<template>
  <section>
    <div class="container home-page">
      <div class="center-container" >
        <div class="message">Keppnin er hafin!</div>
        <div class="description">
          Þú gætir unnið 100 þúsund kr. gjafabréf frá Icelandair ef þú þekkir Cheerio-vision stjörnurnar!
        </div>
        <a href="#" class="btn" @click="start">Hefja keppni</a>
        <div class="winner-message">Við drögum út heppinn þátttakanda 15. maí</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    start() {
      this.$emit('start', this.answers);
    }
  }
}
</script>