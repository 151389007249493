<template>
  <main class="app">
    <div class="app-logo">
      <a href="/">
        <img src="/img/q-logo.svg" alt="Logo">
      </a>
    </div>
    <Quiz/>
  </main>
</template>

<script>
import Quiz from "./Quiz.vue";

export default {
  components: {
    Quiz
  }
};
</script>

<style lang="less">
  @import "../assets/less/application";
</style>